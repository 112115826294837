<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos</h4>
            <div class="small text-muted">Administración de productos y todas sus variedades</div>
          </b-col>
          
          <b-col sm="5">
            <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Productos">
              <b-dropdown-item @click="add()" v-if="!isCostSuppliersProducts"><i class="fa fa-plus"></i> Agregar Producto</b-dropdown-item>              
              <b-dropdown-item @click="openProductsWeb()"><i class="fa fa-sitemap "></i> Configurar Productos para Sitio Web</b-dropdown-item>
              <b-dropdown-item @click="openImportCSV()" v-if="!isCostSuppliersProducts"><i class="fa fa-file-excel-o "></i> Gestor de Importaciones</b-dropdown-item>
            </b-dropdown>
            
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>
      
      <b-row>
        <b-col lg="12" id="view_filters_products">    
          <b-card v-if="table.mostrarFiltros" id="list-products">
            <b-row>
              <b-col sm="3">
                <v-select :options="arr.select.active" v-model="filters.active" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                <b-form-input type="text" 
                              placeholder="Ingrese un código o nombre de producto" 
                              v-model="filters.name"
                              size="sm"
                              v-on:keyup.enter.native="filterProducts">
                </b-form-input>                
              </b-col>  
              <b-col sm="3">
                <v-select :options="arr.select.categorys" v-model="filters.categorys" placeholder="Categorías" :multiple="false" :select-on-tab="true" @input="filterLoadSubCategorys" ></v-select>
                <v-select :options="arr.select.subcategorys" v-model="filters.subcategorys" placeholder="Subcategorías" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              
              <b-col sm="3" v-if="hasLines">
                <v-select :options="arr.select.lines" v-model="filters.lines" placeholder="Lineas" :multiple="false" :select-on-tab="true" @input="filterLoadSubLines"></v-select>
                <v-select :options="arr.select.sublines" v-model="filters.sublines" placeholder="Sublineas" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>

              <b-col sm="3">
                <b-row>
                  <b-col lg="12">
                    <b-button variant="outline-dark" @click="filterProducts()" size="sm" class="pull-right">
                      <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                      Filtrar
                    </b-button>
                  </b-col>
                  <b-col lg="12">
                    <b-link @click="openExport()" class="mt-3 pull-right">
                      Exportar
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col :lg="getColsProduct" class="pr-0" id="view_list_products">
          <b-card>
            <b-row>
              <b-col sm="12">
                <b-form-checkbox v-model="filters.show_price_iva" 
                                  switch 
                                  size="sm" 
                                  class="pull-left"
                                  @input="filterProducts()">
                  Mostrar precios con IVA incluido
                </b-form-checkbox>                 
              </b-col>
              <b-col sm="12" class="mt-2">
                <b-table class="mb-0 table-products-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected" 
                        :busy="table.isBusy"                     
                        v-if="table.items.length || table.isBusy">                                               
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:row-details="row">
                      <b-row class="w-100 p-0 m-0">
                        <b-col lg="6" v-if="row.item.subcategory">
                          <legend class="crud-products-title">Categorías</legend>
                          <b-icon icon="chevron-double-right"></b-icon> {{row.item.subcategory.category.name}}<br>
                          <b-icon icon="blank" ></b-icon>
                          <b-icon icon="chevron-right"></b-icon> {{row.item.subcategory.name}}                          
                        </b-col>                         
                        <b-col lg="6" v-if="row.item.subline">
                          <div v-if="hasLines">
                            <legend class="crud-products-title">Linea</legend>
                            <b-icon icon="chevron-double-right" ></b-icon> {{row.item.subline.line.name}} <br>
                            <b-icon icon="blank" ></b-icon>
                            <b-icon icon="chevron-right" ></b-icon> {{row.item.subline.name}}<br>
                          </div>
                        </b-col>                       
                      </b-row>
                    </template>
                                                  
                    <template v-slot:cell(id)="data">
                      <b>{{data.item.id}}</b>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div class="crud-products-title-table">
                        <span v-if="data.item.code" class="crud-products-code-table">
                          {{data.item.code}}<br>
                        </span>
                        {{data.item.name}}
                      </div>
                    </template>
                    
                    <template v-slot:cell(prices)="data">                                
                      <div v-if="data.item.prices && data.item.prices.length<3">
                        <div v-for="element in data.item.prices" :key="element.id" :title="element.list.name" v-b-tooltip.hover>
                          <b>
                            <span v-if="element.list.reference">
                              {{element.list.reference.substring(0,5)}}
                            </span>
                            <span v-else>
                              {{element.list.name.substring(0,5)}}
                            </span>                              
                          </b> : 
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}} 
                          <span v-if="element.unit_measurement_result">{{element.unit_measurement_result.reference}}</span>
                          <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                            <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <a href="javascript:void(0)" @click="setPricesProducts(data.item)">Ver Listas</a>
                      </div>
                    </template>

                    <template v-slot:cell(prices_iva)="data">                                
                      <div v-if="data.item.prices && data.item.prices.length<3">
                        <div v-for="element in data.item.prices" :key="element.id" :title="element.list.name + ' | IVA: ' + element.aliquot_iva +'%'" v-b-tooltip.hover>
                          <b>
                            <span v-if="element.list.reference">
                              {{element.list.reference.substring(0,5)}}
                            </span>
                            <span v-else>
                              {{element.list.name.substring(0,5)}}
                            </span>                              
                          </b> : 
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total_iva)}} 
                          <span v-if="element.unit_measurement_result">{{element.unit_measurement_result.reference}}</span>
                          <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                            <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <a href="javascript:void(0)" @click="setPricesProducts(data.item)">Ver Listas</a>
                      </div>
                    </template>

                    <template v-slot:cell(stock)="data">     
                      <div v-if="!getVariantProduct(data.item)">                      
                        <div v-if="data.item.stock>0">
                          <span class="text-success">{{data.item.stock}}</span>                        
                        </div>
                        <div v-else>
                          <span class="text-danger">{{data.item.stock}}</span>
                        </div>
                      </div>
                    </template>

                    <template v-slot:cell(variant)="data">                            
                      <div v-if="hasColorsWaist">                  
                        <b-button v-if="data.item.colors_waist.length" 
                                  @click="openVariant(data.item)"
                                  variant="secondary"
                                  size="sm"
                                  class="crud-products-table-button-variant">
                          {{data.item.colors_waist.length}} Items
                          <b-icon icon="chevron-right"></b-icon>
                        </b-button>
                        <b-button v-else 
                                  @click="openVariant(data.item)"
                                  variant="outline-secondary"
                                  size="sm"
                                  v-b-tooltip.hover
                                  title="Cargar Variedades"
                                  class="crud-products-table-button-variant">
                          <b-icon icon="chevron-double-right"></b-icon>
                        </b-button>
                      </div>
                      <div v-else>
                        <div v-if="hasColors">
                          <b-button v-if="data.item.colors.length"
                                    @click="openVariant(data.item)"
                                    variant="secondary"
                                    size="sm"
                                    class="crud-products-table-button-variant">
                            {{data.item.colors.length}} Colores
                            <b-icon icon="chevron-right"></b-icon>
                          </b-button>
                          <b-button v-else 
                                    @click="openVariant(data.item)"
                                    variant="outline-secondary"
                                    size="sm"
                                    v-b-tooltip.hover
                                    title="Cargar Colores"
                                    class="crud-products-table-button-variant">
                            <b-icon icon="chevron-double-right"></b-icon>
                          </b-button>                          
                        </div>
                        <div v-else>
                          <div v-if="hasWaist">
                            <b-button v-if="data.item.waist.length"
                                      @click="openVariant(data.item)"
                                      variant="secondary"
                                      size="sm"
                                      class="crud-products-table-button-variant">
                              {{data.item.waist.length}} Talles
                              <b-icon icon="chevron-right"></b-icon>
                            </b-button>
                            <b-button v-else 
                                      @click="openVariant(data.item)"
                                      variant="outline-secondary"
                                      size="sm"
                                      v-b-tooltip.hover
                                      title="Cargar Talles"
                                      class="crud-products-table-button-variant">
                              <b-icon icon="chevron-double-right"></b-icon>
                            </b-button>                            
                          </div>
                        </div>
                      </div>
                    </template>       

                    <template v-slot:cell(observations)="data">
                      <div v-if="data.item.observations">
                        <b-icon icon="chat-square-text" :id="'popover-target-observations-' + data.item.id"></b-icon>                      
                        <b-popover :target="'popover-target-observations-' +  data.item.id" triggers="hover" placement="top">
                          <template #title><b>Observaciones</b></template>
                          <div v-html="data.item.observations" class="popover-target-observations"></div>
                        </b-popover>                      
                      </div>  
                    </template>
                              
                    <template v-slot:cell(order)="data">
                      <b-badge variant="dark">{{data.item.order}}</b-badge>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="" 
                                  size="sm" 
                                  title="Acción"
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableProductsShow"
                                  @hide="setConfigTableProductsHide">
                        
                        <b-dropdown-item @click="openProductsWeb(data.item)">
                          <i class="fa fa-edit crud-product-action-icon"></i> Configurar Web
                        </b-dropdown-item>
                        <b-dropdown-item @click="setPricesProducts(data.item)" v-if="modules.showPrices">
                          <i class="fa fa-dollar crud-product-action-icon"></i> Precios
                        </b-dropdown-item>
                        <b-dropdown-item @click="setStockProducts(data.item)" v-if="!getVariantProduct(data.item)" v-show="!parameters.hideStock">
                          <i class="fa fa-cubes crud-product-action-icon crud-product-action-icon-stock"></i> Stock
                        </b-dropdown-item>

                        <b-dropdown-header>Acciones</b-dropdown-header>                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)" v-if="!isCostSuppliersProducts">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="5" id="contain_variant_items" v-if="hasAnyVariant">
          <div id="variant_items">
            <b-card v-if="showVariant">            
              <b-row>              
                <b-col md="12">
                  
                  <!-- PRODUCTO SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="6">
                      <div class="crud-products-title" v-if="itemSelected">
                        <div class="crud-products-title">
                          <span v-if="itemSelected.code" class="crud-products-code">
                            {{this.itemSelected.code}} 
                          </span><br>
                          {{this.itemSelected.name}}
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="12" class="crud-product-close-variant">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="showVariant" @click="showVariant=false" v-b-tooltip.hover title="Ocultar Variedad">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>
                      
                        <b-col md="12" class="crud-product-add-variant">
                          <b-button @click="addSub(itemSelected)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Variedad">
                            <i class="fa fa-plus"></i> Agregar
                          </b-button>
                        </b-col> 
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- VARIANTES -->                
                  <b-row>
                    <!-- COLOR - TALLE -->
                    <b-col md="12" v-if="hasColorsWaist">                    
                      <b-table  id="table-color-waist"
                                class="mb-0 table-products-variant"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSubColorsWaist.items"
                                :fields="tableSubColorsWaist.fields"                            
                                v-if="tableSubColorsWaist.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 

                        <template v-slot:cell(color)="data">
                          {{data.item.color.name}}                                
                        </template>

                        <template v-slot:cell(waist)="data">                                    
                          {{data.item.waist.name}}
                        </template>

                        <template v-slot:cell(prices)="data">                    
                          <div v-if="data.item.prices && data.item.prices.length<3">
                            <div v-for="(element, index) in data.item.prices" :key="element.id" :title="element.list.name" v-b-tooltip.hover>
                              <b>
                                <span v-if="element.list.reference">
                                  {{element.list.reference.substring(0,5)}}
                                </span>
                                <span v-else>
                                  {{element.list.name.substring(0,5)}}
                                </span>              
                              </b> :
                              <span v-if="(element.amount_total != itemSelected.prices[index].amount_total) || (element.desc != itemSelected.prices[index].desc)">                              
                                <span>
                                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}
                                </span>
                                <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                                  <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                                </span>                                
                              </span>
                              <span v-else>
                                Heredado
                              </span>                                                            
                            </div>
                          </div>
                          <div v-else>
                            <a href="javascript:void(0)" @click="setPricesProductsVariant(data.item)">Ver Listas</a>
                          </div>
                        </template>

                        <template v-slot:cell(prices_iva)="data">                    
                          <div v-if="data.item.prices && data.item.prices.length<3">
                            <div v-for="(element, index) in data.item.prices" :key="element.id" :title="element.list.name" v-b-tooltip.hover>
                              <b>
                                <span v-if="element.list.reference">
                                  {{element.list.reference.substring(0,5)}}
                                </span>
                                <span v-else>
                                  {{element.list.name.substring(0,5)}}
                                </span>              
                              </b> :
                              <span v-if="(element.amount_total != itemSelected.prices[index].amount_total) || (element.desc != itemSelected.prices[index].desc)">                              
                                <span>
                                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}
                                </span>
                                <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                                  <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                                </span>                                
                              </span>
                              <span v-else>
                                Heredado
                              </span>                                                            
                            </div>
                          </div>
                          <div v-else>
                            <a href="javascript:void(0)" @click="setPricesProductsVariant(data.item)">Ver Listas</a>
                          </div>
                        </template>

                        <template v-slot:cell(stock)="data">                    
                          <div v-if="data.item.stock>0">
                            <span class="text-success">{{data.item.stock}}</span>                        
                          </div>
                          <div v-else>
                            <span class="text-danger">{{data.item.stock}}</span>
                          </div>
                        </template>

                        <template v-slot:cell(f_action)="data">
                          <b-dropdown right 
                                      text="" 
                                      size="sm" 
                                      variant="outline-dark" 
                                      class="pull-right" 
                                      @show="setConfigTableVariantShow"
                                      @hide="setConfigTableVariantHide">
                            <b-dropdown-item @click="setPricesProductsVariant(data.item)"  v-if="modules.showPrices">
                              <i class="fa fa-dollar crud-product-action-icon"></i> Precios
                            </b-dropdown-item>     
                            <b-dropdown-item @click="setStockProductsVariant(data.item)" v-show="!parameters.hideStock">
                              <i class="fa fa-cubes crud-product-action-icon crud-product-action-icon-stock"></i> Stock
                            </b-dropdown-item>

                            <b-dropdown-header>Acciones</b-dropdown-header>                         
                            <b-dropdown-item @click="editSub(data.item)">
                              <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="removeSub(data.item)">
                              <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>

                      </b-table>
                      <b-alert v-else variant="warning" show>No se encontraron variedades de colores + talles</b-alert>
                    </b-col>
                    <b-col v-else>
                      <b-row>
                        <!-- COLOR-->
                        <b-col md="12" v-if="hasColors">                        
                          <b-table class="mb-0 table-products-variant"
                                  responsive
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="true"
                                  :items="tableSubColors.items"
                                  :fields="tableSubColors.fields"                            
                                  v-if="tableSubColors.items.length">   

                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:cell(color)="data">
                              {{data.item.color.name}}                                
                            </template>

                            <template v-slot:cell(prices)="data">                    
                              <div v-if="data.item.prices && data.item.prices.length<3">
                                <div v-for="(element, index) in data.item.prices" :key="element.id" :title="element.list.name" v-b-tooltip.hover>
                                  <b>
                                    <span v-if="element.list.reference">
                                      {{element.list.reference.substring(0,5)}}
                                    </span>
                                    <span v-else>
                                      {{element.list.name.substring(0,5)}}
                                    </span>              
                                  </b> :                                  
                                  <span v-if="(element.amount_total != itemSelected.prices[index].amount_total) || (element.desc != itemSelected.prices[index].desc)">
                                    <span>
                                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}
                                    </span>    
                                    <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                                      <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                                    </span>
                                  </span>                                                                
                                  <span v-else>
                                    Heredado
                                  </span>                                  
                                </div>
                              </div>
                              <div v-else>
                                <a href="javascript:void(0)" @click="setPricesProductsVariant(data.item)">Ver Listas</a>
                              </div>                              
                            </template> 

                            <template v-slot:cell(prices_iva)="data">                    
                              <div v-if="data.item.prices && data.item.prices.length<3">
                                <div v-for="(element, index) in data.item.prices" :key="element.id" :title="element.list.name" v-b-tooltip.hover>
                                  <b>
                                    <span v-if="element.list.reference">
                                      {{element.list.reference.substring(0,5)}}
                                    </span>
                                    <span v-else>
                                      {{element.list.name.substring(0,5)}}
                                    </span>              
                                  </b> :                                  
                                  <span v-if="(element.amount_total != itemSelected.prices[index].amount_total) || (element.desc != itemSelected.prices[index].desc)">
                                    <span>
                                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}
                                    </span>    
                                    <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                                      <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                                    </span>
                                  </span>                                                                
                                  <span v-else>
                                    Heredado
                                  </span>                                  
                                </div>
                              </div>
                              <div v-else>
                                <a href="javascript:void(0)" @click="setPricesProductsVariant(data.item)">Ver Listas</a>
                              </div>                              
                            </template> 

                            <template v-slot:cell(stock)="data">                    
                              <div v-if="data.item.stock>0">
                                <span class="text-success">{{data.item.stock}}</span>                        
                              </div>
                              <div v-else>
                                <span class="text-danger">{{data.item.stock}}</span>
                              </div>
                            </template>

                            <template v-slot:cell(f_action)="data">
                              <b-dropdown right 
                                          text="" 
                                          size="sm" 
                                          variant="outline-dark" 
                                          class="pull-right" 
                                          @show="setConfigTableVariantShow"
                                          @hide="setConfigTableVariantHide">
                                <b-dropdown-item @click="setPricesProductsVariant(data.item)"  v-if="modules.showPrices">
                                  <i class="fa fa-dollar crud-product-action-icon"></i> Precios
                                </b-dropdown-item>     
                                <b-dropdown-item @click="setStockProductsVariant(data.item)" v-show="!parameters.hideStock">
                                  <i class="fa fa-cubes crud-product-action-icon crud-product-action-icon-stock"></i> Stock
                                </b-dropdown-item>

                                <b-dropdown-header>Acciones</b-dropdown-header>                                             
                                <b-dropdown-item @click="editSub(data.item)">
                                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                </b-dropdown-item>
                                <b-dropdown-item @click="removeSub(data.item)">
                                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>

                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron variedades de colores</b-alert>
                        </b-col>

                        <!-- TALLE -->
                        <b-col md="12" v-if="hasWaist">                                                
                          <b-table class="mb-0 table-products-variant"
                                  responsive
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="true"
                                  :items="tableSubWaist.items"
                                  :fields="tableSubWaist.fields"                            
                                  v-if="tableSubWaist.items.length">   
                            
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:cell(waist)="data">                                    
                              {{data.item.waist.name}}
                            </template>

                            <template v-slot:cell(prices)="data">                    
                              <div v-if="data.item.prices && data.item.prices.length<3">
                                <div v-for="(element, index) in data.item.prices" :key="element.id" :title="element.list.name" v-b-tooltip.hover>
                                  <b>
                                    <span v-if="element.list.reference">
                                      {{element.list.reference.substring(0,5)}}
                                    </span>
                                    <span v-else>
                                      {{element.list.name.substring(0,5)}}
                                    </span>              
                                  </b> :                   
                                  <span v-if="(element.amount_total != itemSelected.prices[index].amount_total) || (element.desc != itemSelected.prices[index].desc)">                              
                                    <span>
                                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}
                                    </span>
                                    <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                                      <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                                    </span>
                                  </span>                                                         
                                  <span v-else>
                                    Heredado
                                  </span>
                                </div>
                              </div>
                              <div v-else>
                                <a href="javascript:void(0)" @click="setPricesProductsVariant(data.item)">Ver Listas</a>
                              </div>                              
                            </template>

                            <template v-slot:cell(prices_iva)="data">                    
                              <div v-if="data.item.prices && data.item.prices.length<3">
                                <div v-for="(element, index) in data.item.prices" :key="element.id" :title="element.list.name" v-b-tooltip.hover>                                  
                                  <b>
                                    <span v-if="element.list.reference">
                                      {{element.list.reference.substring(0,5)}}
                                    </span>
                                    <span v-else>
                                      {{element.list.name.substring(0,5)}}
                                    </span>              
                                  </b> :
                                  <span v-if="(element.amount_total != itemSelected.prices[index].amount_total) || (element.desc != itemSelected.prices[index].desc)">                              
                                    <span>
                                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total_iva)}}
                                    </span>
                                    <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                                      <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                                    </span>
                                  </span>                                                         
                                  <span v-else>
                                    Heredado
                                  </span>
                                </div>
                              </div>
                              <div v-else>
                                <a href="javascript:void(0)" @click="setPricesProductsVariant(data.item)">Ver Listas</a>
                              </div>                              
                            </template>

                            <template v-slot:cell(stock)="data">                    
                              <div v-if="data.item.stock>0">
                                <span class="text-success">{{data.item.stock}}</span>                        
                              </div>
                              <div v-else>
                                <span class="text-danger">{{data.item.stock}}</span>
                              </div>
                            </template>

                            <template v-slot:cell(f_action)="data">                                                            
                              <b-dropdown right 
                                          text="" 
                                          size="sm" 
                                          variant="outline-dark" 
                                          class="pull-right" 
                                          @show="setConfigTableVariantShow"
                                          @hide="setConfigTableVariantHide">
                                <b-dropdown-item @click="setPricesProductsVariant(data.item)"  v-if="modules.showPrices">
                                  <i class="fa fa-dollar crud-product-action-icon"></i> Precios
                                </b-dropdown-item>     
                                <b-dropdown-item @click="setStockProductsVariant(data.item)" v-show="!parameters.hideStock">
                                  <i class="fa fa-cubes crud-product-action-icon crud-product-action-icon-stock"></i> Stock
                                </b-dropdown-item>

                                <b-dropdown-header>Acciones</b-dropdown-header>                         
                                <b-dropdown-item @click="editSub(data.item)">
                                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                </b-dropdown-item>
                                <b-dropdown-item @click="removeSub(data.item)">
                                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>

                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron variedades de talles</b-alert>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-alert v-else
                    show
                    variant="warning">
              Seleccione un producto de la lista para poder agregar variedades
            </b-alert>
          </div>
        </b-col>
      </b-row>          

      <b-row>
        <b-col :lg="getColsProduct" class="pr-0" id="view_footer_products">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"  
                                @input="filterProducts()"/>
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.filters" 
              :filters="crud.print.filters"
              :reference="crud.print.reference"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="6" class="mb-4">
                <b-form-checkbox v-model="crud.form.active" 
                                  switch 
                                  size="sm" 
                                  class="pull-left"
                                  :disabled="isCostSuppliersProducts">
                  Activar (disponible en el sistema)
                </b-form-checkbox> 
              </b-col>                            
            </b-row>
            <b-row>                        
              <b-col md="3">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.code"
                                required
                                placeholder="Ingresar un código"
                                :disabled="isCostSuppliersProducts">
                  </b-form-input>
                </b-form-group>
              </b-col>                   
              <b-col md="9">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre"
                                :disabled="isCostSuppliersProducts">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Código de Barra">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.barcode"
                                required
                                placeholder="Ingresar un código de barra"
                                :disabled="isCostSuppliersProducts">
                  </b-form-input>
                </b-form-group>
              </b-col>               
            </b-row>     

            <b-row>   
              <b-col md="6">
                <b-row>   
                  <b-col md="12">                
                    <FindObject type="productsCategory" 
                                @select-object="loadProductsCategory($event)" 
                                :valueID="crud.form.category_id"
                                tag="Categorías"
                                render="search"
                                :disabled="isCostSuppliersProducts"/>
                  </b-col>
                  <b-col md="12">                
                    <FindObject type="productsSubCategory" 
                                @select-object="loadProductsSubcategory($event)" 
                                :valueID="crud.form.subcategory_id"
                                tag="Sub Categorias"
                                :where="conditionals.subcategorys"
                                :key="crud.form.category_id"
                                render="search"
                                :disabled="isCostSuppliersProducts"/>
                  </b-col>
                </b-row>
              </b-col>      
              <b-col md="6" v-if="hasLines">
                <b-row>   
                  <b-col md="12">                                
                    <FindObject type="productsLine" 
                                @select-object="loadProductsLine($event)" 
                                :valueID="crud.form.line_id"
                                tag="Lineas"
                                render="search"
                                :disabled="isCostSuppliersProducts"/>
                  </b-col>
                  <b-col md="12">                                
                    <FindObject type="productsSubLine" 
                                @select-object="loadProductsSubline($event)" 
                                :valueID="crud.form.subline_id"
                                tag="Sub Linea"
                                :where="conditionals.sublines"
                                :key="crud.form.line_id"
                                render="search"
                                :disabled="isCostSuppliersProducts"/>                    
                  </b-col>
                </b-row>
              </b-col>          
            </b-row>
          </b-tab>

          <b-tab no-body title="Unidad Medida" v-if="parameters.haveUnitMeasurement">
            <b-row>              
              <b-col lg="4" v-if="crud.form.haveCostProductPurchase">
                <b-form-group :label="'Unidad de Medida de Compra'">
                  <b-form-input type="text"
                                :readonly="true"
                                size="sm"                                
                                v-model="crud.form.unit_measurement_purchase.name"
                                placeholder="Ingrese un valor">
                  </b-form-input>            
                </b-form-group>                                
              </b-col>
              <b-col lg="4" v-else>
                <FindObject type="unitMeasurement" 
                            tag="Unidad de Medida de Compra"
                            @select-object="loadUnitMeasurementPurchase($event)" 
                            :valueID="crud.form.unit_measurement_purchase_id"
                            render="search"/>                       
              </b-col>
                            
              <b-col lg="4">
                <FindObject type="unitMeasurement" 
                            tag="Unidad de Medida de Venta"
                            @select-object="loadUnitMeasurementSale($event)" 
                            :valueID="crud.form.unit_measurement_sale_id"
                            render="search"/>                       
              </b-col>
              <b-col lg="3" v-if="crud.form.unit_measurement_purchase_id != crud.form.unit_measurement_sale_id">
                <b-form-group label="Variable" description="Variable de Conversión">
                  <b-form-input type="number"
                                size="sm"
                                step="0.01"
                                v-model="crud.form.unit_measurement_sale_conversion"
                                placeholder="Ingrese un valor">
                  </b-form-input>            
                </b-form-group>
              </b-col>                
            </b-row>            
          </b-tab>

          <b-tab no-body title="Observaciones">
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <vue-editor v-model="crud.form.observations"></vue-editor>                              
                </b-form-group>   
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Precios" v-if="showPriceEdit">
            <b-row>
              <b-col lg="8">
                <b-table class="mb-0 crud-products-table-prices"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="tablePrices.items"
                        :fields="tablePrices.fields"                            
                        v-if="tablePrices.items && tablePrices.items.length">   

                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template> 
                  
                  <template v-slot:cell(list)="data">                
                    <div v-if="data.item.list">
                      {{data.item.list.name}}
                      <span v-if="data.item.list.public" class="crud-product-list-prices-public">
                        (Pública)
                      </span>
                    </div>
                  </template>                                                
                  
                  <template v-slot:cell(price_list)="data">       
                    <div v-if="data.item.unit_measurement_initial.reference != data.item.unit_measurement_result.reference">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_initial, data.item.desc, data.item))}}                
                      <span v-if="data.item.unit_measurement_initial">{{data.item.unit_measurement_initial.reference}}</span>
                    </div>
                  </template>

                  <template v-slot:cell(price_public)="data">                
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_total, data.item.desc, data.item))}}                
                    <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
                  </template>

                  <template v-slot:cell(desc)="data">       
                    <div v-if="parseFloat(data.item.desc) > 0">
                      {{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(data.item.desc)/100)}}
                    </div>
                  </template>

                  <template v-slot:cell(price_desc)="data">                
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                    <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
                  </template>                  

                  <template v-slot:cell(price_iva)="data">                
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total_iva)}}                
                    <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
                  </template>

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron precios para este producto</b-alert>
              </b-col> 
            </b-row>
          </b-tab>

          <b-tab no-body title="Fiscal" v-if="isProcessSales">
            <b-row>
              <b-col lg="6">
                <FindObject render="search"
                            type="erpIvaConditions" 
                            @select-object="loadIvaConditions($event)" 
                            :valueID="crud.form.erp_iva_condition_id"
                            :disabled="isCostSuppliersProducts"/>                   
              </b-col>              
            </b-row>            
          </b-tab>   

          <b-tab no-body title="Cuenta Contable" v-if="isProcessAccounting">
            <b-row>
              <b-col lg="6">
                <FindObject render="search"
                        type="accountingAccountsImputation" 
                        @select-object="loadAccountingAccounts($event)" 
                        :valueID="crud.form.accounting_accounts_id"
                        :where="conditionAccountingAccountsFilters"/>
              </b-col>              
            </b-row>            
          </b-tab>   

          <b-tab title="Ordenamiento" v-if="modules.showPrices">
            <b-row>
              <b-col md="12">
                <b-form-group label="Orden" description="Valor utilizado para ordenar en la impresión de listas de precios, catálogo web y flujo del sistema">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.order"
                                required
                                placeholder="Ingresar un orden numérico">
                  </b-form-input>
                </b-form-group>
              </b-col>         
            </b-row>
          </b-tab>                      

          <b-tab title="Apps de Terceros" v-if="parameters.showExternalApp">
            <b-row>
              <b-col md="12">
                <b-form-checkbox v-model="crud.form.show_external_app" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Muestra en Apps de Terceros.
                </b-form-checkbox> 
              </b-col>         
            </b-row>
          </b-tab>                                
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-tabs card>

          <b-tab no-body title="Variantes">
            <b-row>
              <b-col md="12">
                <b-form-checkbox v-model="crud.formSub.active" 
                                  switch 
                                  size="sm" 
                                  class="mb-4 pull-left">
                  Activar
                </b-form-checkbox>            
              </b-col>                 
              <b-col md="12">
                <FindObject type="products" 
                            @select-object="loadVarietyProducts($event)" 
                            :valueID="crud.formSub.products_id"
                            tag="Producto"/>
              </b-col>
                        
              <b-col md="3">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSub.code"
                                required
                                placeholder="Ingresar un código">
                  </b-form-input>
                </b-form-group>
              </b-col>                   
              <b-col md="9">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSub.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col> 
            
              <b-col md="12">
                <b-row> 
                  <b-col md="4">                                                                       
                    <b-form-group label="Variante">
                      <b-form-select v-model="crud.formSub.typeVariant" 
                                     :options="arr.select.typeVariant" 
                                     :disabled="true"></b-form-select>
                    </b-form-group>                
                  </b-col>
                  <b-col v-show="crud.formSub.typeVariant=='COLOR' || crud.formSub.typeVariant=='COLOR-WAIST'">                    
                      <FindObject type="productsColors" 
                                  @select-object="loadVarietyColors($event)" 
                                  :valueID="crud.formSub.colors_id"                                  
                                  tag="Color"/>
                  </b-col>
                  <b-col v-show="crud.formSub.typeVariant=='WAIST' || crud.formSub.typeVariant=='COLOR-WAIST'">
                      <FindObject type="productsWaist" 
                                  @select-object="loadVarietyWaist($event)" 
                                  :valueID="crud.formSub.waist_id"                                  
                                  tag="Talle"/>                         
                  </b-col>               
                </b-row>            
              </b-col> 
            </b-row>
          </b-tab>
          
          <b-tab no-body title="Precios" v-if="showPriceVariantEdit">
            <b-col md="12">
              <b-table class="mb-0"
                      responsive
                      head-variant="dark"
                      :hover="true"
                      :small="true"
                      :fixed="true"
                      :items="tablePricesVariant.items"
                      :fields="tablePricesVariant.fields"                            
                      v-if="tablePricesVariant.items.length">   

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template> 

                <template v-slot:cell(list)="data">                
                  {{data.item.list.name}}
                  <span v-if="data.item.list.public" class="crud-product-list-prices-public">
                    (Pública)
                  </span>
                </template>                                                
                
                <template v-slot:cell(price)="data">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_total, data.item.desc, data.item))}}                
                </template>

                <template v-slot:cell(desc)="data">       
                  <div v-if="parseFloat(data.item.desc) > 0">
                    {{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(data.item.desc)/100)}}
                  </div>
                </template>

                <template v-slot:cell(price_desc)="data">                
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                  <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
                </template>

                <template v-slot:cell(price_iva)="data">                
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total_iva)}}                
                  <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
                </template>                
              </b-table>
              <b-alert v-else variant="warning" show>No se encontraron precios para esta variedad de producto</b-alert>
            </b-col> 
          </b-tab>

          <b-tab title="Apps de Terceros" v-if="parameters.showExternalApp && crud.formSub.typeVariant=='COLOR'">
            <b-row>
              <b-col md="12">
                <b-form-checkbox v-model="crud.formSub.show_external_app" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Muestra en Apps de Terceros.
                </b-form-checkbox> 
              </b-col>         
            </b-row>
          </b-tab>                                          
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD PRECIOS -->
      <b-modal  v-model="modal.formPrice.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formPrice.title}}
        </div>

        <b-row>         
          <b-col md="12">
            <div class="crud-products-title" v-if="itemSelected">
              <div class="crud-products-title">
                <span v-if="itemSelected.code" class="crud-products-code">
                  {{this.itemSelected.code}} 
                </span><br>
                {{this.itemSelected.name}}
              </div>
              <hr>
            </div>
          </b-col>
          <b-col md="4">
            <b-form-group label="Lista">
              <v-select :options="arr.select.listPrice" 
                        v-model="crud.formPrice.price_list_select" 
                        placeholder="Lista"
                        :disabled="crud.formPrice.id>0 || isCostSuppliersProducts">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Descuento">
              <b-form-input type="number"
                            v-model="crud.formPrice.desc"
                            step="0.01"
                            max="100"
                            placeholder="Ingresar el Descuento"
                            :disabled="isCostSuppliersProducts">
              </b-form-input>
            </b-form-group>            
          </b-col>                
          <b-col md="4">
            <b-form-group label="Precio">
              <b-form-input type="number"
                            v-model="crud.formPrice.amount"
                            step="0.01"
                            placeholder="Ingresar el precio"
                            :disabled="isCostSuppliersProducts">
              </b-form-input>
            </b-form-group>            
          </b-col>        
          <b-col md="2">                
            <b-button variant="dark" 
                      @click="saveFormPrice()"
                      title="Guardar Precio"
                      class="crud-products-button-save-price pull-right"
                      :disabled="isCostSuppliersProducts">
              <b-icon icon="plus" v-if="crud.formPrice.id==0"></b-icon>
              <i class="fa fa-save" v-else></i>
            </b-button> 
            <b-button variant="outline-danger" 
                      @click="cancelSaveFormPrice()"
                      title="Cancelar Edición"
                      class="crud-products-button-save-price pull-right mr-1"    
                      :disabled="isCostSuppliersProducts"                  
                      v-if="crud.formPrice.id>0">
              <i class="fa fa-close"></i>
            </b-button>                                  
          </b-col>
          <b-col md="12">
            <b-table class="mb-0 crud-products-table-prices"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tablePrices.items"
                    :fields="tablePrices.fields"                            
                    v-if="tablePrices.items && tablePrices.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 
              
              <template v-slot:cell(list)="data">                
                <div v-if="data.item.list">
                  {{data.item.list.name}}
                  <span v-if="data.item.list.public" class="crud-product-list-prices-public">
                    (Pública)
                  </span>
                </div>
              </template>                                                

              <template v-slot:cell(price_list)="data">       
                <div v-if="data.item.unit_measurement_initial.reference != data.item.unit_measurement_result.reference">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_initial, data.item.desc, data.item))}}                
                  <span v-if="data.item.unit_measurement_initial">{{data.item.unit_measurement_initial.reference}}</span>
                </div>
              </template>

              <template v-slot:cell(price_public)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_total, data.item.desc, data.item))}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(desc)="data">       
                <div v-if="parseFloat(data.item.desc) > 0">
                  {{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(data.item.desc)/100)}}
                </div>
              </template>

              <template v-slot:cell(price_desc)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(price_iva)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total_iva)}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right 
                            size="sm" 
                            variant="outline-dark" 
                            class="pull-right" 
                            :disabled="crud.formPrice.id>0"
                            v-if="!isCostSuppliersProducts">
                  <b-dropdown-item @click="editPricesProducts(data.item)">
                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="removePricesProducts(data.item)">
                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron precios para este producto</b-alert>
          </b-col> 
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPrice.active=false">Salir</b-button>          
        </div>
      </b-modal>

      <!-- CRUD PRECIOS VARIEDADES -->
      <b-modal  v-model="modal.formPriceVariant.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formPriceVariant.title}}
        </div>

        <b-row>         
          <b-col md="12" class="mb-2">
            <div class="crud-products-title" v-if="itemSelected && itemSelectedVariant">
              <b-row>
                <b-col md="6" class="crud-products-title">
                  <span v-if="itemSelected.code" class="crud-products-code">
                    {{this.itemSelected.code}} 
                  </span><br>
                  {{this.itemSelected.name}}
                </b-col>
                <b-col md="6" class="crud-products-title">              
                  <span v-if="itemSelectedVariant.code" class="crud-products-code">
                    {{this.itemSelectedVariant.code}} 
                  </span><br>
                  {{this.itemSelectedVariant.name}} <br>
                  
                  <div v-if="itemSelectedVariant.color">                     
                    <b-icon icon="dot"></b-icon>
                    {{this.itemSelectedVariant.color.name}}
                  </div>                                     
                  <div v-if="itemSelectedVariant.waist">
                    <b-icon icon="dot"></b-icon>
                    {{this.itemSelectedVariant.waist.name}}
                  </div>
                  
                </b-col>              
              </b-row>
              <hr>
            </div>
          </b-col>

          <b-col md="12" v-if="crud.formPriceVariant.id>0">
            <b-row>
              <b-col md="4">
                <b-form-group label="Lista">
                  <v-select :options="arr.select.listPrice" 
                            v-model="crud.formPriceVariant.price_list_select" 
                            placeholder="Lista"
                            :disabled="true">
                  </v-select>
                </b-form-group>
              </b-col>    
              <b-col md="2">
                <b-form-group label="Descuento">
                  <b-form-input type="number"
                                v-model="crud.formPriceVariant.desc"
                                step="0.01"
                                max="100"
                                placeholder="Ingresar el Descuento">
                  </b-form-input>
                </b-form-group>            
              </b-col>                
              <b-col md="4">
                <b-form-group label="Precio">
                  <b-form-input type="number"
                                v-model="crud.formPriceVariant.amount"
                                step="0.01"
                                placeholder="Ingresar el precio">
                  </b-form-input>
                </b-form-group>            
              </b-col>
              <b-col md="2">                
                <b-button variant="dark" 
                          @click="saveFormPriceVariant()"
                          title="Guardar Precio"
                          class="crud-products-button-save-price pull-right">
                  <i class="fa fa-save"></i>
                </b-button> 
                <b-button variant="outline-danger" 
                          @click="cancelSaveFormPriceVariant()"
                          title="Cancelar Edición"
                          class="crud-products-button-save-price pull-right mr-1">
                  <i class="fa fa-close"></i>
                </b-button>                                  
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12">
            <b-table class="mb-0"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="tablePricesVariant.items"
                    :fields="tablePricesVariant.fields"                            
                    v-if="tablePricesVariant.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>              

              <template v-slot:cell(list)="data">                
                {{data.item.list.name}}
                <span v-if="data.item.list.public" class="crud-product-list-prices-public">
                  (Pública)
                </span>
              </template>                                                
              
              <template v-slot:cell(price)="data">
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_total, data.item.desc, data.item))}}                
              </template>

              <template v-slot:cell(desc)="data">       
                <div v-if="parseFloat(data.item.desc) > 0">
                  {{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(data.item.desc)/100)}}
                </div>
              </template>

              <template v-slot:cell(price_desc)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(price_iva)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total_iva)}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right 
                            text="Acción" 
                            size="sm" 
                            variant="outline-dark" 
                            class="pull-right" 
                            :disabled="crud.formPriceVariant.id>0">
                  <b-dropdown-item @click="editPricesProductsVariant(data.item)">
                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron precios para esta variedad de producto</b-alert>
          </b-col> 
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPriceVariant.active=false">Salir</b-button>          
        </div>
      </b-modal>

      <!-- CRUD STOCK -->
      <b-modal  v-model="modal.formStock.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="md">
        <div slot="modal-header">
          {{this.modal.formStock.title}}
        </div>

        <b-row>       
          <b-col md="12">    
            <div class="crud-products-title" v-if="itemSelected">
              <div class="crud-products-title">
                <span v-if="itemSelected.code" class="crud-products-code">
                  {{this.itemSelected.code}} 
                  <br>
                </span>
                {{this.itemSelected.name}}
              </div>              
            </div>
          </b-col>

          <b-col md="12" v-if="isProcessDeposit">            
            <hr>
          </b-col>
          <b-col md="12" v-if="isProcessDeposit">            
            <b-table class="mb-0"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tableStock.items"
                    :fields="tableStock.fields"                            
                    v-if="tableStock.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>               

              <template v-slot:cell(quantity)="data">                            
                {{ parseFloat(data.item.quantity).toFixed(2) }} 
                <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
              </template>                 
            </b-table>
            <b-alert show v-else variant="warning">No se encontró stock</b-alert>
          </b-col>

          <b-col md="12" v-if="!isProcessDeposit">            
            <hr>
          </b-col>
          <b-col md="12" v-if="!isProcessDeposit && crud.formStock.unit_measurement">            
              <b-form-group label="Cantidad Disponible" 
                            description="Ingrese la cantidad actual para este producto.">
                <b-input-group size="sm" :append="crud.formStock.unit_measurement.reference">
                  <b-form-input type="number"
                                v-model="crud.formStock.stock"
                                step="0.01"
                                placeholder="Ingresar stock">
                  </b-form-input>
                </b-input-group>
              </b-form-group>            
          </b-col> 
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formStock.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveFormStock()" v-if="!isProcessDeposit">Actualizar</b-button>          
        </div>

      </b-modal>  

      <!-- CRUD STOCK VARIEDADES -->
      <b-modal  v-model="modal.formStockVariant.active"
                header-bg-variant="dark"
                header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formStockVariant.title}}
        </div>

        <b-row>       
          <b-col md="12" class="mb-2">
            <div class="crud-products-title" v-if="itemSelected && itemSelectedVariant">
              <b-row>
                <b-col md="6" class="crud-products-title">
                  <span v-if="itemSelected.code" class="crud-products-code">
                    {{this.itemSelected.code}} 
                    <br>
                  </span>
                  {{this.itemSelected.name}}
                </b-col>
                <b-col md="6" class="crud-products-title">              
                  <span v-if="itemSelectedVariant.code" class="crud-products-code">
                    {{this.itemSelectedVariant.code}} 
                    <br>
                  </span>
                  {{this.itemSelectedVariant.name}} <br>
                  
                  <div v-if="itemSelectedVariant.color">                     
                    <b-icon icon="dot"></b-icon>
                    {{this.itemSelectedVariant.color.name}}
                  </div>                                     
                  <div v-if="itemSelectedVariant.waist">
                    <b-icon icon="dot"></b-icon>
                    {{this.itemSelectedVariant.waist.name}}
                  </div>
                  
                </b-col>              
              </b-row>              
            </div>
          </b-col>

          <b-col md="12" v-if="isProcessDeposit">            
            <hr>
          </b-col>
          <b-col md="12" v-if="isProcessDeposit">            
            <b-table class="mb-0"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tableStock.items"
                    :fields="tableStock.fields"                            
                    v-if="tableStock.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>               

              <template v-slot:cell(quantity)="data">                            
                {{ parseFloat(data.item.quantity).toFixed(2) }} 
                <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
              </template>                 
            </b-table>
            <b-alert show v-else variant="warning">No se encontró stock</b-alert>
          </b-col>

          <b-col md="12" v-if="!isProcessDeposit">            
            <hr>
          </b-col>
          <b-col md="12" v-if="!isProcessDeposit && crud.formStock.unit_measurement">            
              <b-form-group label="Cantidad Disponible" 
                            description="Ingrese la cantidad actual para esta variedad">
                <b-input-group size="sm" :append="crud.formStock.unit_measurement.reference">
                  <b-form-input type="number"
                                v-model="crud.formStockVariant.stock"
                                step="0.01"
                                placeholder="Ingresar stock">
                  </b-form-input>
                </b-input-group>
              </b-form-group>            
          </b-col> 
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formStockVariant.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveFormStockVariant()" v-if="!isProcessDeposit">Actualizar</b-button>          
        </div>

      </b-modal>  

      <!-- CRUD IMPORTACIONES -->
      <b-modal  v-model="modal.formImport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formImport.title}}
        </div>
        
        <b-tabs pills card vertical>
          <b-tab title="Nuevos Productos" @click="crud.formImport.type='import_store_products'" active >
            <b-card-text>
              <b-row>       
                <b-col md="12" class="mb-2">
                  <b-alert show variant="info">
                      <h4 class="alert-heading">Importación de Productos</h4>
                      
                      <div>
                        <b-icon icon="arrow-right-short"/> El sistema importará todos los registros cargados en el archivo.
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Esta importación solo permite agregar <b>productos</b>.
                      </div>                      
                      <div>
                        <b-icon icon="arrow-right-short"/> Si el <b>código</b> ya existe en la base de datos, se omite dicho registro. 
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Las <b>categoría, subcategoría, linea y sublinea</b>, sino existe las crea (busca coincidencia por nombre).
                      </div>
                      <div v-if="hasColors || hasColorsWaist">
                        <b-icon icon="arrow-right-short"/> El <b>color</b> asignado a la variedad, sino existe las crea (busca coincidencia por nombre).
                      </div>
                      <div v-if="hasWaist || hasColorsWaist">
                        <b-icon icon="arrow-right-short"/> El <b>talle</b> asignado a la variedad, sino existe las crea (busca coincidencia por nombre).
                      </div>                                              
                      
                      <hr>

                      <div class="mb-0" v-if="!hasAnyVariant">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-products.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>
                      <div class="mb-0" v-if="hasColors && !hasColorsWaist">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-products-colors.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>
                      <div class="mb-0" v-if="hasWaist && !hasColorsWaist">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-products-waist.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>
                      <div class="mb-0" v-if="hasColorsWaist">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-products-colors-waist.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>                                                                  
                    </b-alert>                  
                </b-col>
                <b-col md="12" class="mb-2">                  
                  <b-form-group label="Archivo CSV [Productos]">                
                    <b-form-file v-model="crud.formImport.file"
                                  size="sm"
                                  :state="Boolean(crud.formImport.file)"
                                  placeholder="Elija un archivo o sueltelo aquí..."
                                  drop-placeholder="Suelta el archivo aquí...">
                    </b-form-file>                    
                  </b-form-group>         
                </b-col> 
              </b-row>              
            </b-card-text>
          </b-tab>
          <b-tab title="Actualización de Precios" @click="crud.formImport.type='import_update_products_prices'">
            <b-card-text>          
              <b-row>
                <b-col md="12" class="mb-2">
                  <b-alert show variant="info">
                      <h4 class="alert-heading">Actualización de Precios</h4>
                      <div>
                        <b-icon icon="arrow-right-short"/> El sistema importará todos los registros cargados en el archivo. <br>
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Esta importación solo permite actualizar precio de productos por lista de precios.
                      </div>                      
                      <div>
                        <b-icon icon="arrow-right-short"/> Con el <b>código</b> de producto, se busca en la base de datos para identificar el producto. <br>
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Buscará el precio en la <b>lista de precio</b> seleccionada y le asigna el precio cargado en el archivo. <br>
                      </div>
                      
                      <hr>
  
                      <div class="mb-0" v-if="!hasAnyVariant">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-price-products.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>
                      <div class="mb-0" v-if="hasColors && !hasColorsWaist">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-price-products-colors.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>
                      <div class="mb-0" v-if="hasWaist && !hasColorsWaist">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-price-products-waist.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>
                      <div class="mb-0" v-if="hasColorsWaist">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/products/csv-format-price-products-colors-waist.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>                      
                    </b-alert>                  
                </b-col>                
                <b-col md="12" class="mb-2">  
                  <FindObject type="priceList" 
                              @select-object="loadListPriceImportCSV($event)" 
                              :valueID="crud.formImport.price_list_id"
                              render="search"/>
                </b-col>                   
                <b-col md="12" class="mb-2">  
                  <b-form-group label="Archivo CSV [Precio Productos]">                
                    <b-form-file v-model="crud.formImport.file"
                                  size="sm"
                                  :state="Boolean(crud.formImport.file)"
                                  placeholder="Elija un archivo o sueltelo aquí..."
                                  drop-placeholder="Suelta el archivo aquí...">
                    </b-form-file>                    
                  </b-form-group>   
                </b-col>
              </b-row>                
            </b-card-text>
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formImport.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="importCSV()">Importar</b-button>          
        </div>
      </b-modal>  

      <!-- CRUD EXPORTACIONES -->      
      <b-modal  v-model="modal.formExport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formExport.title}}
        </div>
        
        <b-row>
          <b-col md="12">
            <b-form-group label="Lista">
              <v-select :options="arr.select.listPrice" 
                        v-model="modal.formExport.price_list" 
                        placeholder="Lista">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="modal.formExport.price_list">
            <b-overlay :show="modal.formExport.overlay" rounded="sm">
              <b-table-simple hover small caption-top responsive>    
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>Tipo de Exportación</b-th>
                    <b-th class="text-right">Acciones</b-th>        
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Lista de precios completa. <i>(Aplica Filtros)</i></b-td>
                    <b-td class="text-right">
                      <b-button-group size="sm">
                        <b-button @click="downloadSource('filter-full-list-price')" class="mr-1">
                          <b-icon icon="download"></b-icon>
                          CSV  
                        </b-button>                  
                        <b-button @click="printPDF('filter-full-list-price')" class="mr-1">
                          <b-icon icon="printer"></b-icon>
                          PDF
                        </b-button>                                                          
                      </b-button-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Productos modificados en los últimos 7 días. <i>(Aplica Filtros)</i></b-td>
                    <b-td class="text-right">
                      <b-button-group size="sm">
                        <b-button @click="downloadSource('filter-list-price-last-update')" class="mr-1">
                          <b-icon icon="download"></b-icon>
                          CSV  
                        </b-button>                  
                        <b-button @click="printPDF('filter-list-price-last-update')" class="mr-1">
                          <b-icon icon="printer"></b-icon>
                          PDF
                        </b-button>                                                          
                      </b-button-group>
                    </b-td>
                  </b-tr>                  
                </b-tbody>
              </b-table-simple>
            </b-overlay>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formExport.active=false">Cancelar</b-button>
        </div>
      </b-modal>  

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'  
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Param from '@/config/parameters'  
  import axios from 'axios'
  import Storage from '@/handler/storageSession'
  import { VueEditor } from "vue2-editor";
  import Funciones from '@/handler/funciones'
  import moment from 'moment'
  import Printer from '@/components/inc/printer/printer'

  export default {
    components: {
      FindObject,
      VueEditor,
      Printer,   
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudProducts',
          elements: {}
        }, 
        parameters: {
          haveLines: Helper.hasParametersAccess(Param.P7),
          haveColors: Helper.hasParametersAccess(Param.P8),
          haveWaist: Helper.hasParametersAccess(Param.P9),
          haveMeasurement: Helper.hasParametersAccess(Param.P10),
          haveColorsWaist: Helper.hasParametersAccess(Param.P17),          
          hideStock: Helper.hasParametersAccess(Param.P19),        
          haveUnitMeasurement: Helper.hasParametersAccess(Param.P31), 
          showExternalApp: Helper.hasParametersAccess(Param.P55), 
        },         
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,
        },        
        tableSubColors : {
          items: [],
          fields: []
        },   
        tableSubWaist : {
          items: [],
          fields: []
        },   
        tableSubColorsWaist : {
          items: [],
          fields: []
        },            
        tablePrices : {
          items: [],
          fields: []
        }, 
        tablePricesVariant : {
          items: [],
          fields: []
        }, 
        tableStock : {
          items: [],
          fields: [
            {key: 'deposit', label: 'Deposito', class:"align-middle", width:"250px"},
            {key: 'quantity', label: 'Cantidad', class:"align-middle text-center", width:"100px"},            
          ]
        },                          
        crud: {
          form: {
            id: 0,
            name: '',            
            line: null,
            line_id: 0,            
            subline: null,
            subline_id: 0,
            category: null,
            category_id: 0,            
            subcategory: null,
            subcategory_id: 0,
            customer: null,
            customer_id: 0,
            code: '',
            image: [],            
            depth: 0,
            long: 0,
            height: 0,
            weight: 0,
            active: true,
            featured: false,
            description: '',            
            description_short: '',     
            fileLink: '',
            file: null,
            published: true,
            fields_optionals: '',
            product_related_type_view: 'default',
            product_related_subcategory_id: 0,
            refused: false,
            variants: [],
            newVariants: '',
            erp_iva_condition: null,
            erp_iva_condition_id: 0,
            accounting_accounts: null,
            accounting_accounts_id: 0,
            videos: [],
            unit_measurement_purchase: null,
            unit_measurement_purchase_id: 0,
            unit_measurement_sale_id: 0,
            unit_measurement_sale: null,
            unit_measurement_sale_conversion: 0,
            haveCostProductPurchase: false,
            published_instagram: false,
            observations: '',
            barcode: '',
            icon_link_external: '',
            legend_link_external: '',
            link_external: '',
            order: 0,
            show_external_app: false,
          }, 
          formSub: {
            id: 0,
            name: '',
            products: null,
            products_id: 0,
            colors: null,
            colors_id: 0,
            waist: null,
            waist_id: 0,
            code: '',
            image: [],
            description: '',   
            active: true,     
            typeVariant: '',
            show_external_app: false, 
          }, 
          formPrice: {
            id: 0,
            products_id: 0,
            price_list_id: 0,
            price_list_select: null,
            amount: 0,
            amount_old: 0,
            desc: 0,
          },   
          formPriceVariant: {
            id: 0,            
            price_list_id: 0,
            price_list_select: null,
            amount: 0,
            amount_old: 0,
            desc: 0,
          },              
          formStock: {
            id: 0,
            products_id: 0,
            stock: 0,            
            unit_measurement: null,
            unit_measurement_id: 0,
            detail: [],
          },              
          formStockVariant: {
            id: 0,
            products_id: 0,
            stock: 0,            
          },
          formImport: {
            file: '',
            price_list: null,
            price_list_id: 0,
            type: ''            
          },
          print: {
            key: 0,
            filters: null,
            reference: 'products-price-list',
          }                                                            
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },       
          formPrice: {
            active: false,
            title: ''
          }, 
          formPriceVariant: {
            active: false,
            title: ''
          },           
          formStock: {
            active: false,
            title: ''
          },    
          formStockVariant: {
            active: false,
            title: ''
          },                                    
          formImport: {
            active: false,
            title: ''
          },
          formExport: {
            active: false,
            title: '',
            price_list: null,
            overlay: false,
          },         
        },
        arr: {
          products: [],   
          depositeZone: [],
          select:{
            listPrice: [],
            lines: [],
            sublines:[],
            categorys: [],
            subcategorys:[],
            active:[
              {code:true, label: 'Activo'},
              {code:false, label: 'Inactivo'},              
            ],
            typeVariant: [
              { value: 'COLOR', text: 'COLOR' },
              { value: 'WAIST', text: 'TALLE' },                 
              { value: 'COLOR-WAIST', text: 'COLOR + TALLE' },                 
            ],
          }          
        },   
        filters: {
          lines: null,
          sublines: null,
          categorys: null,
          subcategorys: null,
          name: '',
          active: {code:true, label: 'Activo'},
          listFullToCSV: false,
          price_list_id: 0,
          last_update_days: 0,
          show_price_iva: false,
        },
        itemSelected: null,   
        itemSelectedVariant: null,
        showVariant: false,
        showPriceEdit: false,
        showPriceVariantEdit: false,
        modulesActive: [], 
        modules: {
          showPrices: false
        },
        conditionals: {
          subcategorys: [
            {field: 'category_id', condition: 0}
          ],
          lines: [
            {field: 'line_id', condition: 0}
          ]          
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.modulesActive = Session.getSession().auth.user.permissions.modules
      this.configAccess()

      this.filterLoad()
      this.loadFieldTable()            
      this.loadListPrice() 
      this.loadStyleConfig()        
      this.loadZone()

      this.filterProducts()
    },
    computed: {
      hasLines() {
        return this.parameters.haveLines
      },
      hasWaist() {
        return this.parameters.haveWaist
      },
      hasColors() {
        return this.parameters.haveColors
      },
      hasColorsWaist() {
        return this.parameters.haveColorsWaist
      },
      hasAnyVariant() {      
        return this.hasColors || this.hasWaist || this.hasColorsWaist
      },
      hasAllVariant() {      
        return this.hasColors && this.hasWaist && this.hasColorsWaist
      },      
      hasMeasurement() {        
        return this.parameters.haveMeasurement
      },
      getColsProduct() {
        if(this.hasAnyVariant) {
          return 7 
        } else {
          return 12
        }
      },
      stateProductVariant() {        
        return this.crud.form.variants.indexOf(this.crud.form.newVariants.trim()) > -1 ? false : null
      },
      isProcessSales() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.VENTAS || element.id == Modules.PRESUPUESTOS) {
            status = true
          }
        })
        return status
      },          
      isProcessAccounting() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {
            status = true
          }
        })
        return status
      },
      isProcessDeposit() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.DEPOSITOS) {
            status = true
          }
        })
        return status
      },
      isCostSuppliersProducts() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.COSTOS) {
            status = true
          }
        })

        return status && !this.hasAnyVariant
      },            

      // CONDITIONS SELECT
      conditionAccountingAccountsFilters(){
          return [
              {field: 'active', condition: true},
              {field: 'type', condition: 'Ingresos'}
          ];
      },
    },
    methods: {
      loadStyleConfig () {        
        var view_list_products = document.getElementById('view_list_products')
        var view_footer_products = document.getElementById('view_footer_products')

        if(this.hasAnyVariant) {
          var padre = document.getElementById('contain_variant_items')
          var hijo = document.getElementById('variant_items')  
          
          hijo.style.width = padre.clientWidth - 45 + 'px'

          if(window.innerWidth < 991) {
            hijo.style.width = '100%'
            hijo.style.position = "relative"
            hijo.style.right = 'auto'

            view_list_products.classList.remove('pr-0')
            view_footer_products.classList.remove('pr-0')
          } else {
            view_list_products.classList.add('pr-0')
            view_footer_products.classList.add('pr-0')
          }
        } else {
          view_list_products.classList.remove('pr-0')
          view_footer_products.classList.remove('pr-0')          
        }
      },
      loadFieldTable () {
        // TABLE PRODUCTOS
        this.table.fields = []
        this.table.fields.push({key: 'id', label: 'ID', class:"align-middle text-center", width:"30px"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"245px"})              
        if(this.modules.showPrices) {
          this.table.fields.push({key: 'prices', label: 'Precio S/IVA', class:"align-middle", width:"150px"})      
        } 
        if(this.modules.showPrices && this.filters.show_price_iva) {
          this.table.fields.push({key: 'prices_iva', label: 'Precio C/IVA', class:"align-middle", width:"150px"})      
        }
        if(!this.parameters.hideStock){
          this.table.fields.push({key: 'stock', label: 'Stock', class:"align-middle text-center", width:"70px"})      
        }
        if(this.hasAnyVariant) {
          this.table.fields.push({key: 'variant', label: 'Variedades', class:"align-middle text-center", width:"95px"})
        }        
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"50px"})
        if(this.modules.showPrices) {          
          this.table.fields.push({key: 'order', label: 'Orden', class: 'text-center align-middle', width:"40px"})
        }        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"20px"})

        // TABLE PRODUCTO COLOR
        this.tableSubColors.fields = []
        this.tableSubColors.fields.push({key: 'color', label: 'Color', class:"align-middle", width:"90px"})
        if(this.modules.showPrices) {
          this.tableSubColors.fields.push({key: 'prices', label: 'Precio S/IVA', class:"align-middle", width:"90px"})
        }
        if(this.modules.showPrices && this.filters.show_price_iva) {
          this.tableSubColors.fields.push({key: 'prices_iva', label: 'Precio C/IVA', class:"align-middle text-right", width:"90px"})
        }            
        if(!this.parameters.hideStock){
          this.tableSubColors.fields.push({key: 'stock', label: 'Stock', class:"align-middle text-center", width:"60px"})
        }
        this.tableSubColors.fields.push({key: 'f_action', label:'', class:"align-middle", width:"20px"})

        // TABLE PRODUCTO TALLE
        this.tableSubWaist.fields = []
        this.tableSubWaist.fields.push({key: 'waist', label: 'Talle', class:"align-middle", width:"50px"})        
        if(this.modules.showPrices) {
          this.tableSubWaist.fields.push({key: 'prices', label: 'Precio S/IVA', class:"align-middle", width:"90px"})
        }
        if(this.modules.showPrices && this.filters.show_price_iva) {
          this.tableSubWaist.fields.push({key: 'prices_iva', label: 'Precio C/IVA', class:"align-middle text-right", width:"90px"})
        }        
        if(!this.parameters.hideStock){
          this.tableSubWaist.fields.push({key: 'stock', label: 'Stock', class:"align-middle text-center", width:"60px"})
        }
        this.tableSubWaist.fields.push({key: 'f_action', label:'', class:"align-middle", width:"20px"})    
        
        // TABLE PRODUCTO COLOR-TALLE
        this.tableSubColorsWaist.fields = []
        this.tableSubColorsWaist.fields.push({key: 'color', label: 'Color', class:"align-middle", width:"90px"})
        this.tableSubColorsWaist.fields.push({key: 'waist', label: 'Talle', class:"align-middle", width:"50px"})
        if(this.modules.showPrices) {
          this.tableSubColorsWaist.fields.push({key: 'prices', label: 'Precio S/IVA', class:"align-middle", width:"90px"})
        }
        if(this.modules.showPrices && this.filters.show_price_iva) {
          this.tableSubColorsWaist.fields.push({key: 'prices_iva', label: 'Precio C/IVA', class:"align-middle text-right", width:"90px"})
        }                    
        if(!this.parameters.hideStock){
          this.tableSubColorsWaist.fields.push({key: 'stock', label: 'Stock', class:"align-middle text-center", width:"60px"})
        }
        this.tableSubColorsWaist.fields.push({key: 'f_action', label:'', class:"align-middle", width:"20px"})

        // TABLE MODAL PRICE 
        this.tablePrices.fields = []
        this.tablePrices.fields.push({key: 'list', label: 'Lista', class:"align-middle", width:"20%"})
        this.tablePrices.fields.push({key: 'price_list', label: '', class:"align-middle text-right", width:"15%"})
        this.tablePrices.fields.push({key: 'price_public', label: 'Precio', class:"align-middle text-right", width:"15%"})
        this.tablePrices.fields.push({key: 'desc', label: 'Descuento', class:"align-middle text-right", width:"10%"})
        this.tablePrices.fields.push({key: 'price_desc', label: 'Precio S/IVA', class:"align-middle text-right", width:"15%"})
        if(this.filters.show_price_iva) {
          this.tablePrices.fields.push({key: 'price_iva', label: 'Precio C/IVA', class:"align-middle text-right", width:"15%"})
        }
        this.tablePrices.fields.push({key: 'f_action', label:'', class:"align-middle", width:"10%"})

        // TABLE MODAL PRICE VARIANT
        this.tablePricesVariant.fields = []
        this.tablePricesVariant.fields.push({key: 'list', label: 'Lista', class:"align-middle", width:"30%"})
        this.tablePricesVariant.fields.push({key: 'price', label: 'Precio', class:"align-middle text-right", width:"15%"})
        this.tablePricesVariant.fields.push({key: 'desc', label: 'Descuento', class:"align-middle text-right", width:"15%"})
        this.tablePricesVariant.fields.push({key: 'price_desc', label: 'Precio S/IVA', class:"align-middle text-right", width:"15%"})
        if(this.filters.show_price_iva) {
          this.tablePricesVariant.fields.push({key: 'price_iva', label: 'Precio C/IVA', class:"align-middle text-right", width:"15%"})
        }
        this.tablePricesVariant.fields.push({key: 'f_action', label:'', class:"align-middle", width:"10%"})
      },
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.products.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openVariant(element)                                  
            }
          }
        })
      },     
      openDetail(index) {
        this.table.rowSelected = index          
        this.filterProducts()        
      },       
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerProducto()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.products = data

          this.table.items.forEach(element => {
            if(!element.active) {
              element._rowVariant = 'danger'  
            }
          });
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }  
          loader.hide()         
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });        
      },
      loadListPrice() {
        var result = serviceAPI.obtenerListaPreciosSimple()        

        result.then((response) => {
          var data = response.data    

          data.forEach(element => {
            this.arr.select.listPrice.push({
              code: element.id,
              label: element.name
            })  
          });    
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });        
      },
      setConfigTableVariantShow() {
        var arrTable = document.getElementsByClassName('table-products-variant')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableVariantHide() {
        var arrTable = document.getElementsByClassName('table-products-variant')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },
      setConfigTableProductsShow() {
        var arrTable = document.getElementsByClassName('table-products-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '285px'
        }        
      },
      setConfigTableProductsHide() {
        var arrTable = document.getElementsByClassName('table-products-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },      
      configAccess() {
        this.modulesActive.forEach(element => {          
          if(element.id == Modules.PRECIOS) {                          
            this.modules.showPrices = true                             
          }
        })
      },
  
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''         
        this.crud.form.line_id = 0    
        this.crud.form.subline_id = 0    
        this.crud.form.category_id = 0  
        this.crud.form.subcategory_id = 0
        this.crud.form.customer_id = 0
        this.crud.form.code = ''
        this.crud.form.image = []        
        this.crud.form.depth = 0
        this.crud.form.long = 0
        this.crud.form.height = 0
        this.crud.form.weight = 0
        this.crud.form.active = true
        this.crud.form.featured = false
        this.crud.form.description = ''        
        this.crud.form.description_short = ''        
        this.crud.form.file = null
        this.crud.form.fileLink = ''
        this.crud.form.published = true
        this.crud.form.product_related_type_view = 'default'
        this.crud.form.product_related_subcategory_id = 0
        this.crud.form.refused = false
        this.crud.form.published_instagram = false
        this.crud.form.observations = ''
        this.crud.form.barcode = ''
        this.crud.form.icon_link_external = ''
        this.crud.form.legend_link_external = ''
        this.crud.form.link_external = ''
        this.crud.form.order = 0
        this.crud.form.show_external_app = false
        
        this.crud.form.variants = []
        this.crud.form.newVariants = ''

        this.crud.form.erp_iva_condition = null
        this.crud.form.erp_iva_condition_id = 0

        this.crud.form.accounting_accounts = null
        this.crud.form.accounting_accounts_id = 0

        this.videosTMP = []
        
        this.crud.form.unit_measurement_purchase = null
        this.crud.form.unit_measurement_purchase_id = 0
        this.crud.form.unit_measurement_sale = null
        this.crud.form.unit_measurement_sale_id = 0
        this.crud.form.unit_measurement_sale_conversion = 0
        this.crud.form.haveCostProductPurchase = false

        this.showPriceEdit = false

        this.modal.form.title = "Nuevo Producto"
        this.modal.form.active = true
      },
      edit(item) {
        let loader = this.$loading.show();

        this.crud.form.id = item.id
        this.crud.form.name = item.name        
                
        if(item.subline) {
          this.crud.form.line_id = item.subline.line_id
          this.setConditionalSubLine(item.subline.line_id)          
          this.crud.form.subline_id =  item.subline_id        
        } else {
          this.crud.form.line_id = 0
          this.setConditionalSubLine(0)
          this.crud.form.subline_id = 0            
        }
        
        if(item.subcategory) {
          this.crud.form.category_id = item.subcategory.category_id
          this.setConditionalSubCategory(item.subcategory.category_id)
          this.crud.form.subcategory_id = item.subcategory_id
        } else {
          this.crud.form.category_id = 0
          this.setConditionalSubCategory(0)
          this.crud.form.subcategory_id = 0          
        }

        this.crud.form.customer_id = item.customer_id
        this.crud.form.code = item.code
        this.crud.form.image = item.image        
        this.crud.form.depth = item.depth
        this.crud.form.long = item.long
        this.crud.form.height = item.height
        this.crud.form.weight = item.weight
        this.crud.form.active = item.active
        this.crud.form.featured = item.featured
        this.crud.form.description = item.description      
        this.crud.form.description_short = (item.description_short!=null) ? item.description_short : ''
        this.crud.form.file = null
        this.crud.form.fileLink = item.attach              
        this.crud.form.published = item.published
        this.crud.form.product_related_type_view = (item.product_related_type_view!=null) ? item.product_related_type_view : 'default'
        this.crud.form.product_related_subcategory_id = (item.product_related_subcategory_id!=null) ? item.product_related_subcategory_id : 0
        this.crud.form.refused = item.refused
        this.crud.form.published_instagram = item.published_instagram
        this.crud.form.observations = (item.observations!=null) ? item.observations : ''
        this.crud.form.barcode = (item.barcode!=null) ? item.barcode : ''
        this.crud.form.icon_link_external = (item.icon_link_external!=null) ? item.icon_link_external : ''
        this.crud.form.legend_link_external = (item.legend_link_external!=null) ? item.legend_link_external : ''
        this.crud.form.link_external = (item.link_external!=null) ? item.link_external : ''
        this.crud.form.order = item.order
        this.crud.form.show_external_app = item.show_external_app

        if(item.variants) {
          this.crud.form.variants = item.variants.split(',')
          this.crud.form.newVariants = ''
        } else {
          this.crud.form.variants = []
          this.crud.form.newVariants = ''          
        }
                
        if(item.image) {          
          this.crud.form.image = []
          item.image.forEach(element => {
            this.crud.form.image.push({ path: element })
          });                    
        } else {
          this.crud.form.image = []
        }

        this.videosTMP = []
        if(item.videos)
          this.videosTMP = JSON.parse(item.videos)
                                
        if(item.product_purchase) {
          this.crud.form.unit_measurement_purchase = item.product_purchase.unit_measurement_purchase
          this.crud.form.unit_measurement_purchase_id = item.product_purchase.unit_measurement_purchase_id
          this.crud.form.haveCostProductPurchase = true
        } else {
          this.crud.form.unit_measurement_purchase = item.unit_measurement_purchase
          this.crud.form.unit_measurement_purchase_id = item.unit_measurement_purchase_id
          this.crud.form.haveCostProductPurchase = false
        }

        this.crud.form.unit_measurement_sale = item.unit_measurement_sale
        this.crud.form.unit_measurement_sale_id = item.unit_measurement_sale_id 
        this.crud.form.unit_measurement_sale_conversion = item.unit_measurement_sale_conversion

        this.crud.form.erp_iva_condition = item.erp_iva_condition
        this.crud.form.erp_iva_condition_id = item.erp_iva_condition_id

        if(item.accounting_account){
          this.crud.form.accounting_accounts = item.accounting_account.accounting_account
          this.crud.form.accounting_accounts_id = item.accounting_account.accounting_accounts_id  
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }

        this.showPriceEdit = true
        this.tablePrices.items = item.prices

        setTimeout(()=>{
          this.modal.form.title = "Editar Producto"
          this.modal.form.active = true

          loader.hide()
        },500)
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Producto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarProducto(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterProducts()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        var file = new FormData();
        if(this.crud.form.fileLink) {
          file.append("file", this.crud.form.fileLink);
        } else {        
          if(this.crud.form.file) {          
            file.append("file", this.crud.form.file);
          }
        }  

        this.crud.form.fields_optionals = []

        this.crud.form.videos = JSON.stringify(this.videosTMP)

        if (this.crud.form.id) {
          var result = serviceAPI.editarProducto(JSON.stringify(this.crud.form), image, file);
        } else {
          var result = serviceAPI.agregarProducto(JSON.stringify(this.crud.form), image, file);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filterProducts()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      
      openImportCSV() {
        this.crud.formImport.type = 'import_store_products'
        this.crud.formImport.file = ''
        this.crud.formImport.price_list = null
        this.crud.formImport.price_list_id = 0
            
        this.modal.formImport.title = "Gestor de Importaciones"
        this.modal.formImport.active = true
      },      
      importCSV() {
        this.$bvModal.msgBoxConfirm('¿Continuar con la importación?', {
          title: 'Importar desde CSV',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'IMPORTAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();

            var file = new FormData();
            file.append("file", this.crud.formImport.file);
            
            var type = ''
            if(!this.hasAnyVariant) {
              type = 'product'
            }          
            if(this.hasColors && !this.hasColorsWaist) {
              type = 'product-colors'
            }                   
            if(this.hasWaist && !this.hasColorsWaist) {
              type = 'product-waist'
            }               
            if(this.hasColorsWaist) {
              type = 'product-colors-waist'
            }                                                  
            
            if(this.crud.formImport.type == 'import_store_products') {    
              var result = serviceAPI.importarProductos(JSON.stringify(this.crud.formImport), file, type);
            }            
            if(this.crud.formImport.type == 'import_update_products_prices') {
              var result = serviceAPI.importarUpdateProductosPrices(JSON.stringify(this.crud.formImport), file, type);
            }
                    
            result.then((response) => {
              this.modal.formImport.active = false
              loader.hide()
              this.filterProducts()
              this.$awn.success("Archivo importado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },
      goDownloadFormat(value) {
        var win = window.open(Helper.getPathPublic(value), '_blank');        
        win.focus();
      },

      addSub(item) {                
        this.crud.formSub.id = 0       
        this.crud.formSub.name = item.name
        this.crud.formSub.products_id = item.id
        this.crud.formSub.colors_id = 0
        this.crud.formSub.waist_id = 0
        this.crud.formSub.code = item.code
        this.crud.formSub.image = []
        this.crud.formSub.description = ''   
        this.crud.formSub.active = true
        this.crud.formSub.show_external_app = false

        if(this.hasColorsWaist) {
          this.crud.formSub.typeVariant = 'COLOR-WAIST'
        } else {
          if(this.hasColors) {
            this.crud.formSub.typeVariant = 'COLOR'
          } else {
            this.crud.formSub.typeVariant = 'WAIST'
          }
        }

        this.showPriceVariantEdit = false

        this.modal.formSub.title = "Nueva Variedad"
        this.modal.formSub.active = true
      },
      editSub(item) {
        this.crud.formSub.id = item.id        
        this.crud.formSub.name = item.name           
        this.crud.formSub.products_id = item.products_id
        this.crud.formSub.colors_id = item.colors_id
        this.crud.formSub.waist_id = item.waist_id
        this.crud.formSub.code = item.code
        this.crud.formSub.image = item.image
        this.crud.formSub.description = item.description
        this.crud.formSub.active = item.active
        this.crud.formSub.show_external_app = item.show_external_app

        if(this.hasColorsWaist) {
          this.crud.formSub.typeVariant = 'COLOR-WAIST'
        } else {
          if(this.hasColors) {
            this.crud.formSub.typeVariant = 'COLOR'
          } else {
            this.crud.formSub.typeVariant = 'WAIST'
          }
        }

        if(item.image) {          
          this.crud.formSub.image = []
          item.image.forEach(element => {
            this.crud.formSub.image.push({ path: element })
          });                    
        } else {
          this.crud.formSub.image = []
        }

        this.showPriceVariantEdit = true
        this.tablePricesVariant.items = item.prices

        this.modal.formSub.title = "Editar Variedad"
        this.modal.formSub.active = true
      },
      removeSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.name = item.name        

        if(this.hasColorsWaist) {
          this.crud.formSub.typeVariant = 'COLOR-WAIST'
        } else {
          if(this.hasColors) {
            this.crud.formSub.typeVariant = 'COLOR'
          } else {
            this.crud.formSub.typeVariant = 'WAIST'
          }
        }

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.formSub.id + ') - '+ this.crud.formSub.name + '?', {
          title: 'Borrar Variedad',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var typeVariant = this.getTypeVariant()                       
            var result = serviceAPI.eliminarVariedad(this.crud.formSub.id, typeVariant);

            result.then((response) => {      
              this.modal.formSub.active = false        
              this.filterProducts(true)     
              loader.hide()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.formSub.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        var typeVariant = this.getTypeVariant()

        if (this.crud.formSub.id) {
          var result = serviceAPI.editarVariedad(JSON.stringify(this.crud.formSub), image, typeVariant);
        } else {
          var result = serviceAPI.agregarVariedad(JSON.stringify(this.crud.formSub), image, typeVariant);
        }

        result.then((response) => {
          this.modal.formSub.active = false                   
          this.filterProducts(true)     
          loader.hide()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },      

      loadProductsLine (object) {
        if(object){
          this.crud.form.line = object
          this.crud.form.line_id = object.id
          this.crud.form.subline = null
          this.crud.form.subline_id = 0

          this.setConditionalSubLine(object.id)
        } else {
          this.crud.form.line = null
          this.crud.form.line_id = 0
          this.crud.form.subline = null
          this.crud.form.subline_id = 0

          this.setConditionalSubLine(object.id)
        }
      },  
      loadProductsSubline (object) {
        if(object){
          this.crud.form.subline = object
          this.crud.form.subline_id = object.id
        } else {
          this.crud.form.subline = null
          this.crud.form.subline_id = 0
        }
      },  
      loadProductsCategory (object) {
        if(object){
          this.crud.form.category = object
          this.crud.form.category_id = object.id    
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0

          this.setConditionalSubCategory(object.id)
        } else {
          this.crud.form.category = null
          this.crud.form.category_id = 0
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0          

          this.setConditionalSubCategory(0)
        }
      },               
      loadProductsSubcategory (object) {
        if(object){
          this.crud.form.subcategory = object
          this.crud.form.subcategory_id = object.id

          this.loadSubcategoryTypeView(object)
          
        } else {
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0

          this.loadSubcategoryTypeView(null)
        }
      },   
      loadVarietyProducts (object) {
        if(object){
          this.crud.formSub.products = object
          this.crud.formSub.products_id = object.id
        } else {
          this.crud.formSub.products = null
          this.crud.formSub.products_id = 0
        }
      }, 
      loadVarietyColors (object) {
        if(object){
          this.crud.formSub.colors = object
          this.crud.formSub.colors_id = object.id
        } else {
          this.crud.formSub.colors = null
          this.crud.formSub.colors_id = 0
        }
      }, 
      loadVarietyWaist (object) {
        if(object){
          this.crud.formSub.waist = object
          this.crud.formSub.waist_id = object.id
        } else {
          this.crud.formSub.waist = null
          this.crud.formSub.waist_id = 0
        }
      },
      loadSubcategoryTypeView (object) {
        if(object) {
          var result = serviceAPI.mostrarCategoria(object.category_id)

          result.then((response) => {
            var data = response.data              

            if(data.subcategorys) {            
              this.optProductRelatedSubcategory = []
              data.subcategorys.forEach(element => {      
                this.optProductRelatedSubcategory.push({text: element.name, value: element.id})                                                            
              });                                
            }          
          })         
        } else {
          this.optProductRelatedSubcategory = []
        }
      },
      loadListPriceImportCSV (object) {
        if(object){
          this.crud.formImport.price_list = object
          this.crud.formImport.price_list_id = object.id
        } else {
          this.crud.formImport.price_list = null
          this.crud.formImport.price_list_id = 0
        }
      },       
      loadIvaConditions (object) {
          if(object){
              this.crud.form.erp_iva_condition = object
              this.crud.form.erp_iva_condition_id = object.id             
          } else {
              this.crud.form.erp_iva_condition = null
              this.crud.form.erp_iva_condition_id = 0
          }
      },      
      loadAccountingAccounts (object) {
          if(object){
              this.crud.form.accounting_accounts = object
              this.crud.form.accounting_accounts_id = object.id             
          } else {
              this.crud.form.accounting_accounts = null
              this.crud.form.accounting_accounts_id = 0
          }
      },
      loadUnitMeasurementSale(object) {
        if(object){
          this.crud.form.unit_measurement_sale = object
          this.crud.form.unit_measurement_sale_id = object.id             
        } else {
          this.crud.form.unit_measurement_sale = null
          this.crud.form.unit_measurement_sale_id = 0
        }
      },
      loadUnitMeasurementPurchase(object) {
        if(object){
          this.crud.form.unit_measurement_purchase = object
          this.crud.form.unit_measurement_purchase_id = object.id             
        } else {
          this.crud.form.unit_measurement_purchase = null
          this.crud.form.unit_measurement_purchase_id = 0
        }
      },      
      
      setConditionalSubLine(id) {
        this.conditionals.sublines = [
          {field: 'line_id', condition: id},
        ]      
      },      
      setConditionalSubCategory(id) {
        this.conditionals.subcategorys = [
          {field: 'category_id', condition: id},
        ]      
      },

      filterLoad() {
        this.filterLoadLines()
        this.filterLoadCategorys()

        this.restoreFilterStorage() 
      },
      filterLoadLines() {
        var result = serviceAPI.obtenerLinea()        
        result.then((response) => {
          var data = response.data    
          
          data.forEach(element => {      
            this.arr.select.lines.push({code: element.id, label: element.name})                                                            
          });                                
        })   
      },
      filterLoadSubLines(val) {        
        this.arr.select.sublines=[]
        if(val) {
          var result = serviceAPI.obtenerLinea()        
          result.then((response) => {
            var data = response.data    
            
            data.forEach(element => {         
              if(element.id == val.code) {
                element.sublines.forEach(element1 => {
                  this.arr.select.sublines.push({code: element1.id, label: element1.name})                        
                }); 
              }
            });                                
          })   
        }
      },
      filterLoadCategorys() {
        var result = serviceAPI.obtenerCategoria()        
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.select.categorys.push({code: element.id, label: element.name})
          });                                
        })   
      },   
      filterLoadSubCategorys(val) {
        this.arr.select.subcategorys = []
        if(val) {          
          var result = serviceAPI.obtenerCategoria()        
          result.then((response) => {
            var data = response.data    

            data.forEach(element => {          
              if(element.id == val.code) {
                element.subcategorys.forEach(element1 => {                
                  this.arr.select.subcategorys.push({code: element1.id, label: element1.name})
                }); 
              }                                  
            });                                
          })   
        }
      },

      filterProducts(forceOpenVariant=false, forceOpenPricesProducts=false, forceOpenPricesProductsVariant=false) {
        this.saveFilterStorage()

        this.table.isBusy = true
        this.filters.listFullToCSV = false
        this.filters.price_list_id = 0
        this.filters.last_update_days = 0
        var result = serviceAPI.filtrarProducto(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data
          this.table.items = data.data          
          this.arr.products = data.data  

          this.table.items.forEach(element => {
            if(!element.active) {
              element._rowVariant = 'danger'                          
            }
            
            if(forceOpenVariant) {
              if(element.id == this.itemSelected.id) {
                this.openVariant(element)
              }              
            }    

            if(forceOpenPricesProducts) {
              if(element.id == this.itemSelected.id) {
                this.setPricesProducts(element)
              }              
            }  

            if(forceOpenPricesProductsVariant) {
              if(element.id == this.itemSelected.id) {
                if(element.colors) {
                  element.colors.forEach(element1 => {
                    if(element1.id == this.itemSelectedVariant.id) {
                      this.setPricesProductsVariant(element1)
                    }
                  });
                }                

                if(element.waist) {
                  element.waist.forEach(element2 => {
                    if(element2.id == this.itemSelectedVariant.id) {
                      this.setPricesProductsVariant(element2)
                    }
                  });             
                }   

                if(element.colors_waist) {
                  element.colors_waist.forEach(element3 => {
                    if(element3.id == this.itemSelectedVariant.id) {
                      this.setPricesProductsVariant(element3)
                    }
                  });                    
                }            
              }              
            }
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }   
                    
          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.loadFieldTable()            

          this.table.isBusy = false
        })           
        .catch(error => {  
          this.table.isBusy = false
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },

      getTypeVariant() {
        var typeVariant = ''
        if(this.hasAllVariant) {          
          if(this.crud.formSub.typeVariant=='COLOR') {
            typeVariant = 'COLOR'
          }
          if(this.crud.formSub.typeVariant=='WAIST') {
            typeVariant = 'WAIST'
          }   
          if(this.crud.formSub.typeVariant=='COLOR-WAIST') {
            typeVariant = 'COLOR-WAIST'
          }                    
        } else {        
          if(this.hasColors) {
            typeVariant = 'COLOR'
          }

          if(this.hasWaist) {
            typeVariant = 'WAIST'
          }          

          if(this.hasColorsWaist) {
            typeVariant = 'COLOR-WAIST'
          }          
        }
        return typeVariant
      },
      getTypeVariantAlt() {
        var typeVariant = ''        
        if(this.hasAnyVariant) {                        
          if(this.hasColors) {
            typeVariant = 'COLOR'
          }

          if(this.hasWaist) {
            typeVariant = 'WAIST'
          }          

          if(this.hasColorsWaist) {
            typeVariant = 'COLOR-WAIST'
          }          
        }
        return typeVariant
      },
      getVariantProduct(item) {
        if(item.colors.length || item.waist.length || item.colors_waist.length) {
          return true
        } else {
          return false
        }        
      },      

      openVariant(item) {
        this.showVariant = true
        this.itemSelected = item
        
        this.tableSubColors.items = item.colors
        this.tableSubColors.items.forEach(element => {
          if(!element.active) {
            element._rowVariant = 'danger'  
          } else {
            element._rowVariant = ''  
          }
        });          
      
        this.tableSubWaist.items = item.waist
        this.tableSubWaist.items.forEach(element => {
          if(!element.active) {
            element._rowVariant = 'danger'  
          } else {
            element._rowVariant = ''  
          }
        });           
      
        this.tableSubColorsWaist.items = item.colors_waist
        this.tableSubColorsWaist.items.forEach(element => {            
          if(!element.active) {
            element._rowVariant = 'danger'  
          } else {
            element._rowVariant = ''  
          }
        });                                   
      },

      sumDiscountPrice(amount, desc, item = null, action = 'viewCalc') {             
        if(item && item.unit_measurement_initial && item.unit_measurement_result && item.unit_measurement_initial.id != item.unit_measurement_result.id) {        
          if(action == 'viewCalc') {           
            if(parseFloat(item.amount_initial) == parseFloat(amount)) {
              return parseFloat(item.amount_initial)
            } else {
              if(desc) {
                var base1 = (100 - parseFloat(desc)) / 100                
                if(parseFloat(base1) == 0) {                            
                  return parseFloat(amount)
                } else {                              
                  return parseFloat(amount) / base1
                }                        
              } else {            
                return parseFloat(amount)
              }
            }          
          } 
          
          if(action == 'formEdit') {           
            return parseFloat(amount)
          }
        } else {    
          if(action == 'viewCalc') {        
            if(desc) {
              var base2 = (100 - parseFloat(desc)) / 100                
              if(parseFloat(base2) == 0) {                            
                return parseFloat(amount)
              } else {              
                return parseFloat(amount) / base2
              }                        
            } else {            
              return parseFloat(amount)
            }   
          }

          if(action == 'formEdit') {           
            return parseFloat(amount)
          }          
        }     
      },
      setPricesProducts(item) {
        this.crud.formPrice.id = 0
        this.crud.formPrice.products_id = item.id
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0
        this.crud.formPrice.desc = 0

        this.tablePrices.items = item.prices
        this.itemSelected = item

        this.modal.formPrice.active = true
        this.modal.formPrice.title = "Mantenimiento de Precios"

        this.loadStyleConfig()
      },
      editPricesProducts(item) {
        this.crud.formPrice.id = item.id
        this.crud.formPrice.products_id = item.products_id
        this.crud.formPrice.price_list_select = { code: item.list.id, label: item.list.name}
        this.crud.formPrice.price_list_id = item.list.id
        this.crud.formPrice.amount = this.sumDiscountPrice(item.amount_initial, item.desc, item, 'formEdit').toFixed(2)      
        this.crud.formPrice.amount_old = this.sumDiscountPrice(item.amount_initial, item.desc, item, 'formEdit').toFixed(2)
        this.crud.formPrice.desc = item.desc
      },
      cancelSaveFormPrice(){
        this.crud.formPrice.id = 0
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0
        this.crud.formPrice.desc = 0
      },
      removePricesProducts(item) {
        var id = item.id
        var listName = 'LISTA: ' + item.list.name        
        var listAmount = 'PRECIO: ' + Intl.NumberFormat('es-AR',{style:'currency',currency:item.list.currency.code}).format(item.amount_total)

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item de la ' + listName + ' con ' + listAmount + '?', {
          title: 'Borrar Precio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var result = serviceAPI.eliminarPrecioVariedad(id);

            result.then((response) => {      
              loader.hide()
              this.filterProducts(false, true)
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          } 
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      saveFormPrice(){
        let loader = this.$loading.show();

        if(this.crud.formPrice.price_list_select) {
          this.crud.formPrice.price_list_id = this.crud.formPrice.price_list_select.code
        }        

        if (this.crud.formPrice.id) {
          var result = serviceAPI.editarPrecioVariedad(this.crud.formPrice)
        } else {
          var result = serviceAPI.agregarPrecioVariedad(this.crud.formPrice)
        }

        result.then((response) => {                         
          loader.hide()
          this.filterProducts(true, true)          
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      
      setPricesProductsVariant(item) {        
        this.crud.formPriceVariant.id = 0        
        this.crud.formPriceVariant.price_list_select = null
        this.crud.formPriceVariant.price_list_id = null
        this.crud.formPriceVariant.amount = 0
        this.crud.formPriceVariant.amount_old = 0
        this.crud.formPriceVariant.desc = 0

        this.tablePricesVariant.items = item.prices
        this.itemSelectedVariant = item

        this.modal.formPriceVariant.active = true
        this.modal.formPriceVariant.title = "VARIEDAD - Mantenimiento de Precios"
      },
      editPricesProductsVariant(item) {
        this.crud.formPriceVariant.id = item.id        
        this.crud.formPriceVariant.price_list_select = { code: item.list.id, label: item.list.name}
        this.crud.formPriceVariant.price_list_id = item.list.id
        this.crud.formPriceVariant.amount = this.sumDiscountPrice(item.amount_total, item.desc, item).toFixed(2)
        this.crud.formPriceVariant.amount_old = this.sumDiscountPrice(item.amount_total, item.desc, item).toFixed(2)
        this.crud.formPriceVariant.desc = item.desc
      },
      cancelSaveFormPriceVariant(){
        this.crud.formPriceVariant.id = 0
        this.crud.formPriceVariant.price_list_select = null
        this.crud.formPriceVariant.price_list_id = null
        this.crud.formPriceVariant.amount = 0
        this.crud.formPriceVariant.amount_old = 0
        this.crud.formPriceVariant.desc = 0
      },
      saveFormPriceVariant(){
        let loader = this.$loading.show(); 

        if (!this.crud.formPriceVariant.id) { 
          this.$awn.alert(Error.showError("No se cargo el item"));
          return false
        }

        var result = serviceAPI.editarPrecioVariedadItem(this.crud.formPriceVariant)        

        result.then((response) => {                         
          loader.hide()
          this.filterProducts(true, false, true)          
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },

      setStockProducts(item) {        
        this.crud.formStock.id = item.id
        this.crud.formStock.products_id = item.id
        this.crud.formStock.stock = item.stock

        this.crud.formStock.unit_measurement = item.unit_measurement_purchase_default
        this.crud.formStock.unit_measurement_id = item.unit_measurement_purchase_default.id

        this.crud.formStock.detail = []
        if(item.depositLocationDetail) {
          item.depositLocationDetail.forEach(element => {
            this.crud.formStock.detail.push({
              'deposit': this.getNameDeposit(element),
              'quantity': element.quantity,
              'unit_measurement': this.crud.formStock.unit_measurement,
            })            
          });
        }
        this.tableStock.items = this.crud.formStock.detail
    
        this.itemSelected = item

        this.modal.formStock.active = true

        if(this.isProcessDeposit) {
          this.modal.formStock.title = "Stock"  
        } else {
          this.modal.formStock.title = "Mantenimiento de Stock"
        }        
      },
      getNameDeposit(item) {        
        var name = ''
        if(this.arr.depositeZone) {
          this.arr.depositeZone.forEach(element => {            
            if(item.location) {
              if(element.id == item.location.zone_id) {              
                name = item.location.zone.deposit.reference + ' - ' + item.location.zone.reference + ' - ' + item.location.reference               
              }
            }
          });
        }
        return name
      },
      saveFormStock(){
        this.$bvModal.msgBoxConfirm('Desea continuar con la actualización de stock?', {
          title: 'Actualizar Stock',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'dark',
          okTitle: 'ACTUALIZAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {

            let loader = this.$loading.show();

            if (!this.crud.formStock.id) { 
              this.$awn.alert(Error.showError("No se cargo el item"));
              return false
            }
            
            var result = serviceAPI.editarProductStock(this.crud.formStock)
            
            result.then((response) => {                         
              loader.hide()
              this.filterProducts()
              this.modal.formStock.active = false
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })     
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })           
      },

      setStockProductsVariant(item) {        
        this.crud.formStockVariant.id = item.id
        this.crud.formStockVariant.stock = item.stock
        
        this.crud.formStock.unit_measurement = this.itemSelected.unit_measurement_purchase_default
        this.crud.formStock.unit_measurement_id = this.itemSelected.unit_measurement_purchase_default.id

        this.crud.formStock.detail = []
        if(item.depositLocationDetail) {
          item.depositLocationDetail.forEach(element => {
            this.crud.formStock.detail.push({
              'deposit': this.getNameDeposit(element),
              'quantity': element.quantity,
              'unit_measurement': this.crud.formStock.unit_measurement,
            })            
          });
        }
        this.tableStock.items = this.crud.formStock.detail

        this.itemSelectedVariant = item

        this.modal.formStockVariant.active = true        

        if(this.isProcessDeposit) {
          this.modal.formStockVariant.title = "VARIEDAD - Stock"
        } else {
          this.modal.formStockVariant.title = "VARIEDAD - Mantenimiento de Stock"
        }                
      },
      saveFormStockVariant(){
        this.$bvModal.msgBoxConfirm('Desea continuar con la actualización de stock?', {
          title: 'Actualizar Stock',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'dark',
          okTitle: 'ACTUALIZAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();

            if (!this.crud.formStockVariant.id) { 
              this.$awn.alert(Error.showError("No se cargo el item"));
              return false
            }

            var typeVariant = this.getTypeVariantAlt()            
            var result = serviceAPI.editarProductStockVariedad(this.crud.formStockVariant, typeVariant)

            result.then((response) => {                         
              loader.hide()
              this.filterProducts(true) 
              this.modal.formStockVariant.active = false
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })       
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                   
      },      
      loadZone() {        
        this.arr.zone = []
        var result = serviceAPI.obtenerDepositoZona()

        result.then((response) => {
          var data = response.data
          this.arr.depositeZone = data           
        })
      },    

      openProductsWeb(item) {
        if(item){
          this.$router.push({ name: 'ProductsStaffCrudProductsWeb', params: {codeProduct: item.code }})
        } else {
          this.$router.push({ name: 'ProductsStaffCrudProductsWeb'})
        }
      },

      // EXPORT
      openExport() {
        this.modal.formExport.active = true
        this.modal.formExport.title = "Exportación de Productos"
      },
      downloadSource(type) {
        var result = null
        var data = null

        switch (type) {
          case 'filter-full-list-price':
            this.modal.formExport.overlay = true

            this.filters.listFullToCSV = true
            this.filters.price_list_id = this.modal.formExport.price_list.code
            this.filters.last_update_days = 0
            result = serviceAPI.filtrarProducto(this.filters)        
            result.then((response) => {          
              data = response.data
              Funciones.exportToCSV(data, this.modal.formExport.price_list.label + '-product-price-list-download')                    
              this.modal.formExport.overlay = false
            })
            .catch(error => {
              this.modal.formExport.overlay = false
            })
            break;
          case 'filter-list-price-last-update':
            this.modal.formExport.overlay = true

            this.filters.listFullToCSV = true
            this.filters.price_list_id = this.modal.formExport.price_list.code
            this.filters.last_update_days = 7
            result = serviceAPI.filtrarProducto(this.filters)        
            result.then((response) => {          
              data = response.data
              Funciones.exportToCSV(data, this.modal.formExport.price_list.label + '-product-price-list-download')                    
              this.modal.formExport.overlay = false
            })
            .catch(error => {
              this.modal.formExport.overlay = false
            })
            break;            
        }        
      },      

      printPDF(type) {        
        switch (type) {
          case 'filter-full-list-price':
            this.filters.listFullToCSV = true
            this.filters.price_list_id = this.modal.formExport.price_list.code
            this.filters.last_update_days = 0
            this.crud.print.key=Math.random()
            this.crud.print.filters=this.filters
            break;

          case 'filter-list-price-last-update':
            this.filters.listFullToCSV = true
            this.filters.price_list_id = this.modal.formExport.price_list.code
            this.filters.last_update_days = 7
            this.crud.print.key=Math.random()
            this.crud.print.filters=this.filters
            break;            
        }
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_crud_product', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_crud_product')) {
          this.filters = JSON.parse(Storage.getValue('filter_crud_product'))
        }         
      },            
    }    
  }
</script>
<style>
  .crud-products-title {
    font-size: 15px;    
    font-weight: 600;
  }
  .crud-products-code {
    color: gray;
  }
  .crud-products-title-table {
    font-size: 12px;    
  }
  .crud-products-code-table {
    color: gray;
    font-size: 10px;    
    font-weight: 600;
  }
  .crud-product-image {
    height: 40px;
  }

  .products-tablesub-observations {
    color: darkred;
  }
  .crud-product-table-show {
    font-size: 15px;
  }  
  .crud-product-add-variant {
    margin-top: 5px;
  }
  .crud-product-close-variant  {
    margin-top: -15px;
  }
  .crud-product-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .crud-product-action-icon-stock {
    color: #000 !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .crud-products-button-save-price {
    margin-top: 30px;
  }  
  .crud-product-list-prices-public {
    font-size: 10px;
    font-weight: 600;
    color: gray;
  }
  .crud-products-variant-positions {
    position: fixed;
  }
  #variant_items {
    position: fixed;
    right: 15px;    
  }     
  .crud-products-table-prices {
    min-height: 100px;
  } 
  .crud-products-table-button-variant {
    padding: 2px;
    padding-left: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .table-products-variant {
    overflow: auto;
    max-height: 350px;
  }
  .crud-product-interested {
    font-size: 13px;
  }
  .crud-product-featured-custom {
    color: red; 
    font-size: 15px;
    float: right;     
  }  
  .popover-target-observations p {
    margin-bottom: 0px;
  }
</style>